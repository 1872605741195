import "core-js/modules/es.array.push.js";
import { FloatingBubble, showToast } from 'vant';
export default {
  components: {
    FloatingBubble
  },
  methods: {
    onClick() {
      this.$router.push({
        path: '/old/index'
      });
    }
  }
};